import { template as template_dbe4cdb34cce4d77a406089583e3cf64 } from "@ember/template-compiler";
const WelcomeHeader = template_dbe4cdb34cce4d77a406089583e3cf64(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
