import { template as template_c0b2a6d350344a8db65ac1ea61352783 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_c0b2a6d350344a8db65ac1ea61352783(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
