import { template as template_4d0a264e3f3641a5b295e97a1512bfeb } from "@ember/template-compiler";
const FKText = template_4d0a264e3f3641a5b295e97a1512bfeb(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
