import { template as template_f36668a6a6af4dfc8efcb3dd9b092b42 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_f36668a6a6af4dfc8efcb3dd9b092b42(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
