import { template as template_28fad31c0fa6407aa1b2e14cf033fa27 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_28fad31c0fa6407aa1b2e14cf033fa27(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
